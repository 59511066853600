import React, { useEffect } from 'react';
import { Box, Snackbar } from '@material-ui/core';
import PageTitle from '../../components/PageTitle';
import * as Colors from '../../styles/colors';
import Fields from '../../components/Fields';
import { useTranslation } from 'react-i18next';


const Charging = ({ setStep, csNumber, socketNumber, chargingData, setChargingData, token, open, handleClose }) => {

  const { t } = useTranslation();

  useEffect(() => {
    getData();
  }
  )

  const getData = async () => {
    await fetch(process.env.REACT_APP_CHARGER_API_ROOT + 'currentStatus?ChargingStationId=' + csNumber + '&ChargingStationSocketId=' + socketNumber, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (res) {
        setChargingData({
          energyKWh: res.data.energyKWh,
          powerKW: ifExists(res.data.powerKW),
          maxPowerKW: ifExists(res.data.maxPowerKW),
          price: res.data.price,
          startTime: ifExists(res.data.startTime),
          endTime: new Date(res.data.endTime)
        })
        if (res.data.status === "complete") {
          setStep(3);
        }
      })

      .catch(error => {
        console.log(error)
      })
    setTimeout(() => getData(), 3000);
  }

  const ifExists = (val) => {
    const res = val ? val : 0;
    return res;
  }

  const formFields = [
    {
      label: t('charging.inputField.chargingStation.label'),
      value: 'Ocean Orchids',
      readonly: true
    },
    {
      label: t('charging.inputField.socket.label'),
      value: 'Number ' + socketNumber,
      readonly: true
    },
    {
      label: t('charging.inputField.startDate.label'),
      value: chargingData.startTime === 0 ? "Charging not started!" : new Date(chargingData.startTime).toLocaleTimeString(),
      readonly: true
    },
    {
      label: t('charging.inputField.chargingTime.label'),
      value: Math.round((new Date(chargingData.endTime).getTime() - new Date(chargingData.startTime).getTime()) / 60000) + ' min',
      readonly: true
    },
    {
      label: t('charging.inputField.chargedSoFar.label'),
      value: (chargingData.energyKWh).toFixed(2) + ' kWh',
      readonly: true
    },
    {
      label: t('charging.inputField.chargingPower.label'),
      value: (chargingData.powerKW).toFixed(2) + '/' + (chargingData.maxPowerKW).toFixed(2) + ' kWh',
      readonly: true
    },
    {
      label: t('charging.inputField.estimatedPrice.label'),
      value: chargingData.price === 0 ? 0 : chargingData.price / 100 + ' €',
      readonly: true
    }
  ];

  return (
    <>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose} message="test" marginThreshold={0}>
        {/* <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
      This is a success message!
    </Alert> */}
      </Snackbar>
      <Box display="flex"
        justifyContent="center"
        flexDirection="column"
        p={1}
        m={1}>
        <div style={styles.title}>
          <PageTitle uppercase={true} title={t('charging.pageTitle')}></PageTitle>
        </div>
        <div>
          {chargingData.startTime === 0
            ? <p>Charging session is initializing...</p>
            : <Fields formFields={formFields}></Fields>
          }
        </div>
      </Box>
    </>
  );
};


const styles = {
  title: {
    color: Colors.geniYellow,
    size: '32px',
    marginBottom: '32px'
  },
  subtitle: {
    color: '#4F4F4F',
    textAlign: 'center'
  },
  disclaimer: {
    fontSize: '14px',
    textAlign: 'center'
  },
  buttonWrapper: {
    marginTop: '1rem',
    textAlign: 'center'
  },
  submitButton: {
    width: '100%',
    marginRigh: '20px',
    height: '50px',
    backgroundColor: Colors.geniYellow,
    color: 'white'
  }
}

export default Charging;