import React from 'react';
import SuccessImage from '../../assets/success.png';
import FailImage from '../../assets/fail.png';
import PageTitle from '../../components/PageTitle';
import { useTranslation } from 'react-i18next';
import * as Colors from '../../styles/colors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShieldCheck } from '@fortawesome/pro-light-svg-icons';

const AuthResponse = (props) => {

    const { t } = useTranslation();

    const authResponseStyle = {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%'
    };
    const imgStyles = {
        height: '3rem',
        width: '3rem'
    };
    const subtitleStlyes = {
        fontFamily: 'Calibri Light',
        fontWeight: 300
    };

    let authResponseImage = SuccessImage;
    let imgAltText = t('authenticationResponse.success.alt');
    let imgTitle = t('authenticationResponse.success.imgTitle');
    let pageTitle = t('authenticationResponse.success.pageTitle');
    let pageSubtitle = t('authenticationResponse.success.instructions');
    let retry = t('authenticationResponse.fail.retry');

    if (!props.success) {
        authResponseImage = FailImage;
        imgAltText = t('authenticationResponse.fail.alt');
        imgTitle = t('authenticationResponse.fail.imgTitle');
        pageTitle = t('authenticationResponse.fail.pageTitle');
        pageSubtitle = t('authenticationResponse.fail.instructions');
    }

    return (
        <div style={authResponseStyle}>
            {
            props.succes ?
                    <FontAwesomeIcon style={{...imgStyles, color: Colors.geniYellow}} icon={faShieldCheck}></FontAwesomeIcon>
                    :
                    <img style={imgStyles}
                        src={authResponseImage}
                        alt={imgAltText}
                        title={imgTitle}></img>
            }

            <PageTitle color={Colors.black} title={pageTitle}></PageTitle>
            <p style={subtitleStlyes}> {pageSubtitle}</p>
            {
                !props.success ?
                    <p style={{ ...subtitleStlyes, fontStyle: 'italic' }}>{retry}</p> : null
            }
        </div>
    )
}

export default AuthResponse;