import fb from './firebase';
import { getAuth, RecaptchaVerifier, signInWithPhoneNumber } from 'firebase/auth';

class Auth {
    constructor() {
        this.authenticated = false;
        this.idToken = null;
        this.auth = getAuth(fb);
        this.auth.settings.appVerificationDisabledForTesting = process.env.environment === 'development' ? true : false;
    }

    async getCaptcha(dom) {
        return new RecaptchaVerifier(this.auth, dom, {
            size: "invisible",
        });
    }

    signIn(phone, recaptcha) {
        return signInWithPhoneNumber(this.auth, phone, recaptcha);
    }

    async login() {
        await this.auth.currentUser.getIdToken().then((token) => {
            this.idToken = token;
            this.authenticated = true;
        })
    }

    getToken() {
        return this.idToken;
    }

    isAuthenticated() {
        return this.authenticated;
    }
}

export default Auth;