import React, { useState } from 'react';
import Welcome from './payment/Welcome';
import PaymentForm from './payment/payment-form/PaymentForm';
import Charging from './payment/Charging';
import Finished from './payment/Finished';
import { Box } from '@material-ui/core';

const PaymentWizard = ({ match, token, state }) => {
    const [step, setStep] = useState(state.step);
    const [chargingData, setChargingData] = useState({
        energyKWh: 0,
        powerKW: 0,
        maxPowerKW: 0,
        price: 0,
        startTime: new Date(),
        endTime: new Date()
    });
    const csNumber = match.params.id;
    const socketNumber = match.params.socket;

    const component = () => {
        if (step === 0)
            return <Welcome setStep={setStep} socketNumber={socketNumber}></Welcome>
        else if (step === 1)
            return <PaymentForm setStep={setStep} token={token} csNumber={csNumber} socketNumber={socketNumber}></PaymentForm>
        else if (step === 2)
            return <Charging setStep={setStep} csNumber={csNumber} socketNumber={socketNumber} chargingData={chargingData} setChargingData={setChargingData} token={token}></Charging>
        else if (step === 3)
            return <Finished socketNumber={socketNumber} chargingData={chargingData}></Finished>
    }
    return (

        <Box display="flex" flexDirection="column" flexGrow={1}>
            {component()}
        </Box>

    )

}

export default PaymentWizard;