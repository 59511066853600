

const validateWithRegExp = (number, regex) => {
    return !!number.match(regex);
}

export const isPhoneNumberValid = (phoneNumber) => {
    if (!phoneNumber)
        return false;
    return validateWithRegExp(phoneNumber, RegExp(/^(\+[0-9]{3})[ ]{0,1}([0-9]{2})[ ]{0,1}([0-9]{3})[ ]{0,1}([0-9]{3})$/));
}

export const isOTPValid = (otp) => {
    if (!otp)
        return false;
    return validateWithRegExp(otp, RegExp(/^([0-9]{6})$/));
}

export const isNameValid = (name) => {
    if (!name)
        return false;
    return name.length > 0;
}

export const isEmailValid = (email) => {
    if (!email)
        return false;
    return validateWithRegExp(email, RegExp(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/));
}

export const isPaymentFormValid = (billingDetails) => {
    return isEmailValid(billingDetails.email) && isNameValid(billingDetails.name);
}