import React, { Component } from 'react';
import { Box } from '@material-ui/core';

import {
  BrowserRouter as Router,
  Route,
} from 'react-router-dom';

import { ProtectedRoute } from './components/ProtectedRoute';
import Login from './pages/Login';
import Missing from './pages/Missing';
import PaymentWizard from './pages/PaymentWizard';
import AuthResponse from './pages/payment/AuthResponse';

export class App extends Component {
  render() {
    const formStyles = {
      box: {

        height: '100%'
      },
      boxWrapper: {
        maxWidth: '480px',
        backgroundColor: "#E8E8E8",
        borderLeft: 'rgba(0,0,0,0.5) 1px solid',
        borderRight: 'rgba(0,0,0,0.5) 1px solid'
      }
    }

    return (
      <>
        <div id="box-wrapper" style={formStyles.boxWrapper}>
          <Box display="flex" p={1} flexDirection="column" style={formStyles.box}>
            <Router>
              <Route path="/" exact component={Missing}></Route>
              <Route path="/:id/:socket" exact component={Login}></Route>
              <ProtectedRoute path="/payment/:id/:socket" exact component={PaymentWizard}></ProtectedRoute>
              <Route path="/response-test" exact component={AuthResponse}></Route>
            </Router>
          </Box>
        </div>
      </>
    );
  }
}

export default App;

