import React from 'react';
import * as Colors from '../styles/colors';

let styles = {
    color: Colors.geniYellow,
    textAlign: 'center'
}

const PageTitle = (props) => {
    if (props.uppercase) {
        styles = { ...styles, textTransform: 'uppercase' }
    }
    if (props.color) {
        styles = { ...styles, color: props.color }
    }
    return (
        <h1 style={styles}>{props.title}</h1>
    );
}

export default PageTitle;