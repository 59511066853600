import React, { useState, useContext } from 'react';
import { Button, Box, Table, TableBody, TableRow, TableCell } from '@material-ui/core';
import * as Colors from '../styles/colors';
import '../styles/Login.css';
import { isOTPValid, isPhoneNumberValid } from '../services/validators';
import Fields from '../components/Fields';
import { withTranslation, useTranslation } from 'react-i18next';
import PageTitle from '../components/PageTitle'
import { useParams, useHistory } from "react-router";
import { AuthContext } from '../context/authContext';

const Login = () => {
  let { id, socket } = useParams();
  let history = useHistory();
  const { t } = useTranslation();

  const [phone, setPhone] = useState('');
  const [otp, setOtp] = useState('');
  const [smsSend, setSmsSend] = useState(false);
  const [codeResult, setCodeResult] = useState('');
  const [phoneBlurred, setPhoneBlurred] = useState(false);
  const [otpBlurred, setOtpBlurred] = useState(false);
  const { authInstance } = useContext(AuthContext);


  const handleChangePhone = (event) => {
    setPhone(event.target.value);
  }

  const handleChangeOtp = (event) => {
    setOtp(event.target.value);
  }

  const sendSms = async() => {
    if (isPhoneNumberValid(phone)) {

      window.recaptchaVer = await authInstance.getCaptcha("recaptcha");

      authInstance.signIn(phone, window.recaptchaVer).then((e) => {
        console.log("code", e);
        setCodeResult(e);
        setSmsSend(true);
      })

        .catch((error) => {
          console.error(error);
        }
        );
    }
  }

  const otpCheck = async () => {

    if (isOTPValid(otp)) {
      try {
        await codeResult.confirm(otp);
        await authInstance.login();
      } catch (error) {
        console.error(error);
      } finally {
        verified();
      }
    }
  }

  const verified = () => {
    fetch(process.env.REACT_APP_CHARGER_API_ROOT + 'currentStatus?ChargingStationId=' + id + '&ChargingStationSocketId=' + socket, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + authInstance.getToken()
      }
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (res) {
        let toStep = 0;
        if (res.data.status === 'charging') {
          toStep = 2;
        }
        history.push({
          pathname: '/payment/' + id + '/' + socket,
          state: { step: toStep }
        });
      })
      .catch(() => {
        history.push({
          pathname: '/payment/' + id + '/' + socket,
          state: { step: 0 }
        });
      })
  }

  // render() {
  let inputField = "";
  let buttonSms = "";
  let textInstructions = "";

  const otpInputFields = [
    {
      error: otpBlurred && !isOTPValid(otp),
      helperText: otpBlurred && !isOTPValid(otp) ? t('login.inputField.otp.helper') : null,
      autoFocus: true,
      placeholder: t('login.inputField.otp.placeholder'),
      label: t('login.inputField.otp.label'),
      id: "standard-basic",
      value: otp,
      onChange: handleChangeOtp,
      onBlur: () => setOtpBlurred(true)
    }
  ];

  const phoneInputFields = [
    {
      error: phoneBlurred && !isPhoneNumberValid(phone),
      helperText: phoneBlurred && !isPhoneNumberValid(phone) ? t('login.inputField.phone.helper') : null,
      placeholder: t('login.inputField.phone.placeholder'),
      autoFocus: true,
      label: t('login.inputField.phone.label'),
      id: "standard-basic",
      value: phone,
      onChange: handleChangePhone,
      onBlur: () => setPhoneBlurred(true)
    }
  ]

  if (smsSend) {
    inputField =
      <Fields formFields={otpInputFields}></Fields>
    buttonSms =
      <Button disabled={!isOTPValid(otp)} style={isOTPValid(otp) ? styles.buttonConfirm : styles.buttonDisabled} variant="outlined" onClick={otpCheck}>{t('login.buttonLabel.otp')}</Button>
    textInstructions = t('login.instructions.otp');

  } else {
    inputField =
      <Fields formFields={phoneInputFields}></Fields>
    buttonSms =
      <Button disabled={!isPhoneNumberValid(phone)} style={isPhoneNumberValid(phone) ? styles.buttonConfirm : styles.buttonDisabled} variant="outlined" onClick={sendSms}>{t('login.buttonLabel.phone')}</Button>
    textInstructions = t('login.instructions.phone');
  }
  return (
    <>
      <Box display="flex"
        justifyContent="center"
        flexDirection="column"
        p={1}
        m={1}>
        <div style={styles.title}>
          <PageTitle uppercase={true} title={t('login.pageTitle')}></PageTitle>
        </div>

        <div style={styles.subtitle}>
          {textInstructions}
        </div>
        <div style={styles.numberInput}>
          {inputField}
        </div>
        <div id="recaptcha">
        </div>
        {!smsSend &&
          <div>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell colSpan="2" style={styles.reservationInfo}>{t('login.priceing.intro')}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={styles.reservationInfo}>{t('login.priceing.preauthorisation')}</TableCell>
                  <TableCell style={styles.reservationInfo}>{t('login.priceing.preauthorisationPrice')}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={styles.reservationInfo}>{t('login.priceing.starting')}</TableCell>
                  <TableCell style={styles.reservationInfo}>{t('login.priceing.startingPrice')}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={styles.reservationInfo}>{t('login.priceing.charging')} </TableCell>
                  <TableCell style={styles.reservationInfo}>{t('login.priceing.chargingPrice')}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </div>
        }
      </Box>
      <Box
        display="flex"
        flexGrow={1}
        flexWrap="wrap"
        alignContent="flex-end"
        justifyContent="center"
        p={1}
        m={1}
      >

        {buttonSms}
      </Box>
    </>
  )
  // }
}

const styles = {
  title: {
    color: Colors.geniYellow,
    size: '32px',
    marginBottom: '32px'
  },
  subtitle: {
    size: '16px',
    textAlign: 'center',
    fontFamily: 'Calibri Light',
    fontWeight: 300
  },
  numberInput: {
    margin: '62px 20px 150px 20px'
  },
  buttonWrapper: {
    marginTop: '32px',
    alignSelf: 'stretch',
  },
  buttonConfirm: {
    maxWidth: '300px',
    width: '100%',
    height: '48px',
    backgroundColor: Colors.geniYellow,
    color: Colors.white,
    size: '18px',
    borderWidth: '0px'
  },
  buttonDisabled: {
    maxWidth: '300px',
    width: '100%',
    height: '48px',
    backgroundColor: Colors.grey,
    color: Colors.white,
    size: '18px',
    borderWidth: '0px'
  },
  reservationInfo: {
    size: '16px',
    fontFamily: 'Calibri Light',
    fontWeight: 300,
    textAlign: 'center'
  }
}

export default withTranslation()(Login);