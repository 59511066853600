
import React from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import CheckoutForm from './CheckoutForm';
import '../../../styles/payment.css';

const ELEMENTS_OPTIONS = {
  fonts: [
    {
      cssSrc: 'https://fonts.googleapis.com/css?family=Calibri',
    },
  ],
};

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY);
const PaymentForm = ({ setStep, token, csNumber, socketNumber }) => {
  return (
    <Elements stripe={stripePromise} options={ELEMENTS_OPTIONS}>
      <CheckoutForm setStep={setStep} token={token} csNumber={csNumber} socketNumber={socketNumber} />
    </Elements>
  );
};

export default PaymentForm;
