import React from 'react';
import { useTranslation, withTranslation } from 'react-i18next';

const Missing = () => {
    const { t } = useTranslation();
    
    return (
        <>
        <div>
            <p>{t('errors.noCSInput')}</p>
        </div>
        </>
    )
}

export default withTranslation()(Missing);