export const geniYellow = '#f6b221';
export const geniYellowTransparent = 'rgba(246,178,33,0.38)';
export const geniBlue = '#005670';

export const white = '#FFFFFF';
export const whiteTransparent = 'rgba(255,255,255,0.3)';

export const black = '#333333';
export const blackTransparent = 'rgba(51,51,51,0.1)';
export const blackLight = '#4F4F4F';
export const blackLightTransparent = 'rgba(79,79,79,0.3)';

export const grey = '#C8C8C8';
export const greyLight = '#E0E0E0';
export const greySuperLight = '#E8E8E8';
export const greySuperLightTransparent = 'rgba(232, 232, 232, 0.90)';
export const greyExtraLight = '#F2F2F2';

export const red = '#CE4139';
export const redPunch = '#DC3545';