import React from 'react';
import { Button, Box } from '@material-ui/core';
import * as Colors from '../../styles/colors';
import Fields from '../../components/Fields';
import PageTitle from '../../components/PageTitle';
import { useTranslation } from 'react-i18next';


const Welcome = ({ setStep, socketNumber }) => {

  const { t } = useTranslation();

  const formFields = [
    {
      label: t('welcome.inputField.chargingStation.label'),
      value: 'Ocean Orchids',
      readonly: true
    },
    {
      label: t('welcome.inputField.socket.label'),
      value: 'Number ' + socketNumber,
      readonly: true
    }
  ];


  return (
    <>
      <Box display="flex"
        justifyContent="center"
        flexDirection="column"
        p={1}
        m={1}>
        <div style={styles.title}>
          <PageTitle uppercase={true} title={t('welcome.pageTitle')}></PageTitle>
        </div>
        <div>
          <Fields formFields={formFields}></Fields>
        </div>
      </Box>
      <Box
        display="flex"
        flexGrow={1}
        flexWrap="wrap"
        alignContent="flex-end"
        justifyContent="center"
        p={1}
        m={1}
      >
        <Button style={styles.buttonConfirm} variant="outlined" onClick={() => setStep(1)}>{t('welcome.buttonLabel.verify')}</Button>
      </Box>
    </>
  );
};


const styles = {
  title: {
    color: Colors.geniYellow,
    size: '32px',
    marginBottom: '32px'
  },
  buttonConfirm: {
    maxWidth: '300px',
    width: '100%',
    height: '48px',
    backgroundColor: Colors.geniYellow,
    color: Colors.white,
    size: '18px',
    borderWidth: '0px'
  }
}

export default Welcome;