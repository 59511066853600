import React, {useContext} from 'react';
import { Route, Redirect } from 'react-router-dom';
import { AuthContext } from '../context/authContext';

export const ProtectedRoute = ({ component: Component, ...rest }) => {

    const { authInstance } = useContext(AuthContext);

    return (
        <Route
            {...rest}
            render={props => {
                if (authInstance.isAuthenticated()) {
                    return <Component {...props} token={authInstance.getToken() } state={props.location.state} />
                } else {
                    return <Redirect to={{
                        pathname: "/",
                        state: {
                            from: props.location
                        }
                    }} />
                }
            }} />
    )
}