import React from 'react';
import { TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import * as Colors from '../styles/colors';
import './Fields.css';

const useStyles = makeStyles({
    labelFocused: {
        "&.MuiFormLabel-root.Mui-focused": {
            color: `${Colors.blackLight}`
        },
        "&.MuiFormLabel-root.Mui-error": {
            color: `${Colors.red}`
        }
    },
    focused: {
        '&::after': {
            borderBottomColor: `${Colors.blackLight}`
        }
    }
});

export default function Fields(props) {

    const classes = useStyles();

    return props.formFields.map(formField => {
        return <TextField
            key={formField.label}
            style={{ width: "100%", paddingBottom: "1rem" }}
            label={formField.label.toUpperCase()}
            value={formField.value}
            required={formField.required}
            autoComplete={formField.autoComplete}
            error={formField.error}
            helperText={formField.helperText}
            id={formField.id}
            type={formField.type}
            autoFocus={formField.autoFocus}
            InputProps={{
                readOnly: formField.readonly,
                classes: {
                    root: classes.root,
                    focused: classes.focused
                }
            }}
            onChange={formField.onChange}
            onBlur={formField.onBlur}
            placeholder={formField.placeholder}
            InputLabelProps={{
                shrink: true,
                classes: {
                    focused: classes.labelFocused,
                    error: classes.labelError
                }
            }}
        />
    })
}