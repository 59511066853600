import React from 'react';
import PageTitle from '../../components/PageTitle';
import * as Colors from '../../styles/colors';
import Fields from '../../components/Fields';
import { useTranslation } from 'react-i18next';
import { Box } from '@material-ui/core';

const Finished = ({ chargingData, socketNumber }) => {
  const { t } = useTranslation();

  const formFields = [
    {
      label: t('finished.inputField.chargingStation.label'),
      value: 'Ocean Orchids',
      readonly: true
    },
    {
      label: t('finished.inputField.socket.label'),
      value: 'Number ' + socketNumber,
      readonly: true
    },
    {
      label: t('finished.inputField.startDate.label'),
      value:  new Date(chargingData.startTime).toLocaleTimeString(),
      readonly: true
    },
    {
      label: t('finished.inputField.endDate.label'),
      value:  new Date(chargingData.endTime).toLocaleTimeString(),
      readonly: true
    },
    {
      label: t('finished.inputField.chargingTime.label'),
      value: Math.round((new Date(chargingData.endTime).getTime() - new Date(chargingData.startTime).getTime()) / 60000) + ' min',
      readonly: true
    },
    {
      label: t('finished.inputField.charged.label'),
      value: (chargingData.energyKWh).toFixed(2) + ' kWh',
      readonly: true
    },
    {
      label: t('finished.inputField.chargedPrice.label'),
      value: chargingData.price/100 + ' €',
      readonly: true
    }
  ];
  return (
    <Box display="flex"
      justifyContent="center"
      flexDirection="column"
      p={1}
      m={1}>
      <div style={styles.title}>
        <PageTitle uppercase={true} title={t('finished.pageTitle')}></PageTitle>
      </div>
      <div>
        <Fields formFields={formFields}></Fields>
      </div>
    </Box>
  );
};

const styles = {
  title: {
    color: Colors.geniYellow,
    size: '32px',
    marginBottom: '32px'
  }
}

export default Finished;